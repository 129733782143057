import React from "react"
import Header from "../components/Home/Header"
import Hero from "../components/Home/Hero"
import Benefits from "../components/Home/Benefits"
import { ThemeProvider } from "../components/themeContext"
import Layout from "../components/Layout"

export default function home() {
  return (
    <ThemeProvider>
      <Layout>
        <Header />
        <Hero />
        <Benefits />
      </Layout>
    </ThemeProvider>
  )
}
