import React, { useState } from "react"
import { Link } from "gatsby"
import pattern from "@static/pattern.svg"

export default function Hero() {
  const [searchInput, setSearchInput] = useState("")
  function handleSearchInput(e) {
    setSearchInput(e.target.value)
  }
  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      this.btn.click()
    }
  }

  return (
    <div>
      <div className="relative bg-gray-50 dark:bg-black overflow-hidden drawer drawer-end">
        <input id="my-drawer-4" type="checkbox" class="drawer-toggle"></input>
        <div
          className="hidden sm:block sm:absolute sm:inset-y-0 sm:h-full sm:w-full"
          aria-hidden="true"
          style={{
            backgroundImage: "url(" + pattern + ")",
            backgroundSize: "100%",
            backgroundPosition: "center",
            width: "100%",
          }}
        ></div>

        <div
          className="relative pt-6 pb-16 sm:pb-24"
          data-todo-x-data="Components.popover({ open: false, focus: true })"
          data-todo-x-init="init()"
          data-todo-at-keydown-escape="onEscape"
          data-todo-at-close-popover-group-window="onClosePopoverGroup"
        >
          <main className="mt-16 mx-auto max-w-7xl px-4 sm:mt-24">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block">A better way to</span>
                {/* space */}
                <span className="block text-indigo-600 xl:inline">
                  sell more domains
                </span>
              </h1>
              <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                Maximize your domain business with performant website built with
                the latest, innovative technologies.
              </p>
              {/* <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
                <div className="rounded-md shadow">
                  <a
                    href="/"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 md:py-4 md:text-lg md:px-10"
                  >
                    Get started
                  </a>
                </div>
                <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3">
                  <a
                    href="/"
                    className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50 md:py-4 md:text-lg md:px-10"
                  >
                    Live demo
                  </a>
                </div>
              </div> */}
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
