import React, { useState, useEffect, useContext } from "react"
import Toggle from "@components/themeToggle"
import { HiHeart } from "react-icons/hi"
import Favorites from "@components/Favorite/Favorites"

export default () => {
  // const context = useContext(pageContextProvider)

  const [toggle, setToggle] = useState(false)
  const [isTop, setIsTop] = useState(true)
  useEffect(() => {
    window.onscroll = () =>
      window.pageYOffset < 10 ? setIsTop(true) : setIsTop(false)

    return () => (window.onscroll = null)
  })

  function handleToggle() {
    document.body.classList.add("modal-open")
    setToggle(true)
  }

  function handleToggleClose() {
    setTimeout(() => {
      setToggle(false)
    }, 500)
  }
  return (
    <div>
      <div
        className={
          "transition duration-300 ease-in-out mx-auto px-4 sm:px-6 fixed z-10 w-full bg-white shadow-md z-20"
        }
      >
        <nav
          className="container m-auto z-10 flex items-center py-8 justify-between sm:h-10 md:justify-center"
          aria-label="Global"
        >
          <div className="flex items-center flex-1 md:w-1/3 md:inset-y-0 md:left-0">
            <div className="flex items-center justify-between w-full md:w-auto">
              <a href="/">
                <span className="sr-only">Workflow</span>
                <img
                  className="h-8 w-auto sm:h-10"
                  src="https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg"
                  alt=""
                ></img>
              </a>
              <div className="-mr-2 flex items-center md:hidden">
                <span className="inline-flex">
                  <label
                    onClick={handleToggle}
                    for="my-drawer-4"
                    class="btn btn-primary"
                  >
                    <HiHeart className="dark:text-gray-400 text-2xl cursor-pointer" />
                  </label>
                </span>
              </div>
            </div>
          </div>
          <div className="hidden md:flex md:space-x-10 md:w-1/3">
            <a
              href="/"
              className="font-medium text-gray-500 hover:text-gray-900"
            >
              Home
            </a>

            <a
              href="/search"
              className="font-medium text-gray-500 hover:text-gray-900"
            >
              Browse All
            </a>

            <a
              href="/"
              className="font-medium text-gray-500 hover:text-gray-900"
            >
              How it Works
            </a>

            <a
              href="/contact"
              className="font-medium text-gray-500 hover:text-gray-900"
            >
              Contact
            </a>
          </div>
          <div className="hidden md:w-1/3 md:flex md:items-center md:justify-end md:inset-y-0 md:right-0">
            <span className="inline-flex rounded-md shadow items-center border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-gray-50">
              <Toggle />
            </span>
            <span className="inline-flex">
              <label
                for="my-drawer-4"
                class="btn btn-primary"
                onClick={handleToggle}
              >
                <HiHeart className="dark:text-gray-400 text-2xl cursor-pointer" />
              </label>
            </span>
          </div>
        </nav>
      </div>
      <div
        class={`fixed inset-x-0 h-screen drawer drawer-end ${toggle && "z-20"}`}
      >
        <input id="my-drawer-4" type="checkbox" class="drawer-toggle"></input>
        <div class="flex flex-col items-center justify-center drawer-content">
          <div className="container h-screen relative overflow-hidden drawer drawer-end">
            <input
              id="my-drawer-4"
              type="checkbox"
              class="drawer-toggle"
            ></input>
            <div
              className="relative pt-6 pb-16 sm:pb-24"
              data-todo-x-data="Components.popover({ open: false, focus: true })"
              data-todo-x-init="init()"
              data-todo-at-keydown-escape="onEscape"
              data-todo-at-close-popover-group-window="onClosePopoverGroup"
            >
              {/* Main */}
            </div>
          </div>
        </div>
        <div class="drawer-side">
          <label
            onClick={handleToggleClose}
            for="my-drawer-4"
            class="drawer-overlay"
          ></label>
          <ul class="menu p-4 overflow-y-auto w-80 bg-base-100 text-base-content">
            <li>
              <a>Menu Item</a>
            </li>
            <li>
              <a>Menu Item</a>
            </li>
            <Favorites />
          </ul>
        </div>
      </div>
    </div>
  )
}
